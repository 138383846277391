import {
    Box,
    Container,
    Text,
    HStack,
    IconButton,
    VStack,
    Center,
    ScaleFade,
    Fade
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import 'react-phone-input-2/lib/material.css';
import { useEffect, useRef, useState } from 'react';
import { auth } from '../shared/auth/firebaseConfig';
import { ConfirmationResult, RecaptchaVerifier } from '@firebase/auth';
import '@styles/login.css';
import LoginWithEmail from '../components/login/LoginWithEmail';
import LoginWithPhone from '../components/login/LoginWithPhone';
import VerifyPhoneCode from '../components/login/VerifyPhoneCode';
// #region redux
import { useSelector } from 'react-redux';
import store, { RootState } from '../redux/store';
// #endregion
import { logger } from '../shared/utility/logger';
import LoginMethod from '../components/login/LoginMethod';
import {
    HttpMethod,
    VA_ENDPOINT,
    httpRequest
} from '../shared/utility/utility';
import User from '../components/login/User';
import React from 'react';
import { waitForToken } from '../shared/auth/token';

const TAG = 'Login';

declare global {
    interface Window {
        recaptchaWidgetId: any;
        recaptchaVerifier: RecaptchaVerifier;
        confirmationResult: ConfirmationResult | undefined;
    }
}

export default function Login() {
    // #region business-logic
    const [showUser, setShowUser] = useState(false);
    const [showBackBtn, setShowBackBtn] = useState(false);
    const [showEmailSignIn, setShowEmailSignIn] = useState(false);
    const [showPhoneSignIn, setShowPhoneSignIn] = useState(false);
    const [showPhoneCode, setShowPhoneCode] = useState(false);
    const currentPhone = useRef('');

    const session = useSelector((state: RootState) => state.session);

    useEffect(() => {
        createCaptcha();
    }, []);

    useEffect(() => {
        // reset login state
        setShowBackBtn(false);
        setShowEmailSignIn(false);
        setShowPhoneSignIn(false);
        setShowPhoneCode(false);
        currentPhone.current = '';

        if (session.loggedIn) {
            setShowUser(true);
        } else {
            setShowUser(false);
        }

        console.log('loggedIn', session.loggedIn);
    }, [session.loggedIn]);

    function handleBackBtn() {
        setShowEmailSignIn(false);

        if (showPhoneCode) {
            setShowPhoneSignIn(true);
        } else {
            setShowPhoneSignIn(false);
            setShowBackBtn(false);
        }

        setShowPhoneCode(false);
    }

    function handleSignInWithEmailBtn() {
        setShowBackBtn(true);
        setShowEmailSignIn(true);
    }

    function handleSignInWithPhoneBtn() {
        setShowBackBtn(true);
        setShowPhoneSignIn(true);
    }

    function handlePhoneSignInBtn() {
        setShowPhoneSignIn(false);
        setShowPhoneCode(true);
    }

    const createCaptcha = () => {
        // this check fixes error: recaptcha has already been rendered in this element
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(
                auth,
                'recaptcha-container',
                {
                    size: 'invisible',
                    callback: (response: any) => {
                        // reCAPTCHA solved, allow signInWithPhoneNumber.
                        logger.info(TAG, 'reCAPTCHA solved');
                    },
                    'expired-callback': () => {
                        // Response expired. Ask user to solve reCAPTCHA again.
                        logger.info(TAG, 'reCAPTCHA expired');
                    }
                }
            );

            // optionally pre-render reCAPTCHA element
            window.recaptchaVerifier.render().then((widgetId: any) => {
                window.recaptchaWidgetId = widgetId;
            });
        }
    };

    const resetCaptcha = () => {
        if (typeof grecaptcha !== 'undefined') {
            grecaptcha.reset(window.recaptchaWidgetId);
        }
    };

    async function authCanceled() {
        logger.info(TAG, 'authCanceled');

        const queryParams = new URLSearchParams(window.location.search);

        // get query params from url
        const redirect_uri = queryParams.get('redirect_uri');
        const response_type = queryParams.get('response_type');
        const state = queryParams.get('state');
        console.log(`redirect_uri: ${redirect_uri}`);
        console.log(`response_type: ${response_type}`);
        console.log(`state: ${state}`);

        if (redirect_uri) {
            // prepare response url
            let redirectUrl = `${decodeURIComponent(
                redirect_uri
            )}?error=access_denied&error_description=The%20user%20denied%20the%20request.%20`;
            if (state) {
                redirectUrl += `&state=${state}`;
            }
            console.log(`redirectUrl: ${redirectUrl}`);

            // redirect to redirect url
            window.location.href = redirectUrl;
        } else {
            // redirect to next page
            // window.location.href = '/devices';
        }
    }

    async function authSuccess() {
        logger.info(TAG, 'authSuccess');

        const queryParams = new URLSearchParams(window.location.search);

        // get token
        const user = auth.currentUser;
        if (user == null) {
            throw new Error('User is null');
        }

        // get query params from url
        const redirect_uri = queryParams.get('redirect_uri');
        const response_type = queryParams.get('response_type');
        const state = queryParams.get('state');
        console.log(`redirect_uri: ${redirect_uri}`);
        console.log(`response_type: ${response_type}`);
        console.log(`state: ${state}`);

        if (redirect_uri && response_type === 'code') {
            await waitForToken();
            const token = store.getState().session.token;
            console.log(`token: ${token}`);

            await httpRequest(`${VA_ENDPOINT}/token/save`, {
                method: HttpMethod.POST,
                headers: new Headers({
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                }),
                body: JSON.stringify({})
            });

            // prepare response url
            const { uid } = user;
            let redirectUrl = `${decodeURIComponent(redirect_uri)}?code=${uid}`;
            if (state) {
                redirectUrl += `&state=${state}`;
            }
            console.log(`redirectUrl: ${redirectUrl}`);

            // redirect to redirect url
            window.location.href = redirectUrl;
        } else {
            // redirect to next page
            // window.location.href = '/devices';
        }
    }

    return (
        <Box width="full">
            {/* <AbsoluteCenter w="full"> */}
            <Container
                w="full"
                maxWidth="600px"
                // background="red"
                // pt={10}
            >
                <Box
                    borderRadius={20}
                    borderColor="#0085FF"
                    borderWidth={2}
                    textAlign="center"
                    className="signInBox"
                    width={'100%'}
                    background="rgba(255, 255, 255, 0.4)"
                >
                    <Fade in={showBackBtn}>
                        <HStack
                            className="backBtn"
                            paddingStart={5}
                            paddingTop={5}
                            visibility={showBackBtn ? 'visible' : 'hidden'}
                        >
                            <IconButton
                                isRound={true}
                                variant="outline"
                                fontSize="20px"
                                icon={<ArrowBackIcon />}
                                aria-label={'Back'}
                                onClick={handleBackBtn}
                            />
                        </HStack>
                    </Fade>

                    <Text className="signInTxt" mt={-5}>
                        Sign In
                    </Text>

                    <Center>
                        <VStack
                            paddingStart={5}
                            paddingEnd={5}
                            mt={8}
                            mb={8}
                            minHeight={'50vh'}
                            maxWidth="400px"
                            w="100%"
                            h="100%"
                        >
                            <ScaleFade
                                in={!showBackBtn && !showUser}
                                className="fillMaxWidth"
                                style={{
                                    minHeight: 'inherit',
                                    marginBottom: '-10px',
                                    display:
                                        !showBackBtn && !showUser
                                            ? 'block'
                                            : 'none'
                                }}
                            >
                                <LoginMethod
                                    handleSignInWithEmailBtn={
                                        handleSignInWithEmailBtn
                                    }
                                    handleSignInWithPhoneBtn={
                                        handleSignInWithPhoneBtn
                                    }
                                    // authSuccess={authSuccess}
                                />
                            </ScaleFade>

                            <ScaleFade
                                in={showEmailSignIn}
                                className="fillMaxWidth"
                                style={{
                                    display: showEmailSignIn ? 'block' : 'none'
                                }}
                            >
                                <div className="fillMaxWidth">
                                    <LoginWithEmail
                                        emailShowing={showEmailSignIn}
                                        // authSuccess={authSuccess}
                                    />
                                </div>
                            </ScaleFade>

                            <ScaleFade
                                in={showPhoneSignIn}
                                className="fillMaxWidth"
                                style={{
                                    display: showPhoneSignIn ? 'block' : 'none'
                                }}
                            >
                                <div className="fillMaxWidth">
                                    <LoginWithPhone
                                        phoneShowing={showPhoneSignIn}
                                        handlePhoneSignInBtn={
                                            handlePhoneSignInBtn
                                        }
                                        resetCaptcha={resetCaptcha}
                                        currentPhone={currentPhone}
                                    />
                                </div>
                            </ScaleFade>

                            <ScaleFade
                                in={showPhoneCode}
                                className="fillMaxWidth"
                                style={{
                                    display: showPhoneCode ? 'block' : 'none'
                                }}
                            >
                                <div className="fillMaxWidth">
                                    <VerifyPhoneCode
                                        phoneCodeShowing={showPhoneCode}
                                        currentPhone={currentPhone}
                                        resetCaptcha={resetCaptcha}
                                        // authSuccess={authSuccess}
                                    />
                                </div>
                            </ScaleFade>

                            <ScaleFade
                                in={showUser}
                                className="fillMaxWidth"
                                style={{
                                    display: showUser ? 'block' : 'none'
                                }}
                            >
                                <div className="fillMaxWidth">
                                    <User
                                        userShowing={showUser}
                                        authCanceled={authCanceled}
                                        authSuccess={authSuccess}
                                    />
                                </div>
                            </ScaleFade>
                        </VStack>
                    </Center>
                </Box>
            </Container>
            {/* </AbsoluteCenter> */}

            <div id="recaptcha-container"></div>
        </Box>
    );
}
